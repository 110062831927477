<template>
  <div>
    <topNav :active="pageIndex" :historyActive="$route.params.oldHistory"></topNav>

    <!-- ======  body  ====== -->
    <div class="container">
      <div class="order">

        <div class="orderyes">
          <div class="orderyes-center">
            <img src="../../assets/certificateList/check-circle.png">
            <span>支付成功 !</span>
          </div>

        </div>
        <div class="order-top" v-if="this.$route.params">
          <div>
            <span>订单编号：</span>
            <span class="order-top-num">{{ this.$route.params.orderSn }}</span>
          </div>
          <div>
            <span>支付金额：</span>
            <span class="order-top-num">￥{{ this.$route.params.totalAmount }}.00</span>
          </div>
          <div v-if="this.$route.params.certificateMsg">
            <span>商品名称：</span>
            <span class="order-top-num">{{ this.$route.params.certificateMsg.name }}</span>
          </div>
          <!-- <div>
            <span>支付订单：</span>
            <span class="order-top-num">{{ orderSn }}</span>
          </div> -->
          <div>
            <span>支付方式：</span>
            <span class="order-top-num">{{ checkPay }}</span>
          </div>
          <div>
            <span>购买时间：</span>
            <span class="order-top-num">{{ timeNow }}</span>
          </div>

        </div>

        <div class="order-btn">
          <el-button type="primary" @click="toHome">返回首页</el-button>
          <el-button plain @click="toMineOrder">查看订单</el-button>
        </div>
      </div>
    </div>
    <footerBottom></footerBottom>

  </div>
</template>

<script>
import topNav from "@/components/nav/nav.vue"
import footerBottom from "@/components/footer/footer.vue"

export default {
  name: "orderpayment",
  data() {
    return {
      pageIndex: 2,
      checkPay: "",
      timer: "",
      timeNow: "",
    }
  },
  mounted() {
    if (this.$route.params.checkPay == 0) {
      this.checkPay = "微信支付"
    } else if (this.$route.params.checkPay == 1) {
      this.checkPay = "支付宝支付"
    } else if (this.$route.params.checkPay == 2) {
      this.checkPay = "银联支付"
    }
    this.GetDateTime()
  },
  components: {
    topNav,
    footerBottom
  },
  methods: {
    toHome() {
      this.$router.push("/")
    },
    toMineOrder() {
      this.$router.push("/MineOrder")
    },
    // 获取当前时间
    GetDateTime() {
      var getDate = new Date;
      let dateYear = getDate.getFullYear();             //获取年 
      let dateMonth = getDate.getMonth() + 1;               //获取月  
      let dateDate = getDate.getDate();                 //获取当日
      let dateHours = getDate.getHours();               //获取小时
      let dateMinutes = getDate.getMinutes();           //获取分钟
      let dateSeconds = getDate.getSeconds();           //获取秒
      dateMonth = dateMonth < 10 ? "0" + dateMonth : dateMonth;
      dateDate = dateDate < 10 ? "0" + dateDate : dateDate;
      dateHours = dateHours < 10 ? "0" + dateHours : dateHours;
      dateMinutes = dateMinutes < 10 ? "0" + dateMinutes : dateMinutes;
      dateSeconds = dateSeconds < 10 ? "0" + dateSeconds : dateSeconds;
      this.timeNow = dateYear + "-" + dateMonth + "-" + dateDate + " " + dateHours + ":" + dateMinutes + ":" + dateSeconds;
    }
  }
}
</script>

<style lang="scss" scoped>
//.logo {
//  width: 58px;
//  height: 58px;
//}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.all {
  background-color: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.14);
}

.nav {
  display: flex;
  justify-content: space-between;
  height: 80px;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  //background-color: #42b983;

  .nav-left {
    display: flex;
    align-items: center;
    //background-color: #99a9bf;
    height: 80px;
    line-height: 80px;

    img {
      display: block;
      width: 60px;
      height: 60px;
    }

    .nav-left-xian {
      padding: 0 24px;
      color: #D9D9D9;
    }

    .nav-left-pay {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    height: 80px;
    line-height: 80px;

    .nav-right-name {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #353535;
      padding-right: 10px;
    }

    img {
      display: block;
      width: 60px;
      height: 60px;
    }
  }
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: 831px;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #fff;

  .orderyes {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #eafff4;
    width: 100%;

    box-sizing: border-box;
    padding: 15px 0;

    .orderyes-center {
      display: flex;
      align-items: center;
      height: 68px;
      width: 831px;

      span {
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        padding-left: 10px;
      }
    }

    img {
      display: block;
      width: 32px;
      height: 32px;
    }
  }

  .order-top {
    //display: flex;
    //flex-direction: column;
    //justify-content: space-around;
    width: 831px;
    //height: 113px;
    padding-top: 32px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    //border-bottom: 1px solid #EDEFF2;

    &>div {
      padding-bottom: 20px;
    }

    .order-top-num {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
    }

    .order-note {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
    }
  }

  .order-btn {
    width: 831px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
}


.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/home/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}
</style>